import React from "react"
import {
  BlogCard,
  Container,
  BlogImage,
  BlogDescription,
  BlogTitle,
  BlogReadTime,
} from "../../../ToolsStyles/BlogSectionStyled"

import BlogImage1 from "../../Assets/word.avif"
import BlogImage2 from "../../Assets/protect.avif"
import BlogImage3 from "../../Assets/jpg.avif"
import BlogImage4 from "../../Assets/excel.avif"

import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { navigate } from "gatsby"
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1174 },
    items: 4,
  },
  laptop: {
    breakpoint: { max: 1174, min: 900 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 900, min: 400 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 400, min: 0 },
    items: 1,
  },
}
const BlogSection = () => {
  return (
    <Container>
      <Carousel responsive={responsive}>
        <BlogCard
          onClick={() => navigate("/blog/guides/how-to-convert-pdf-to-word/")}
        >
          <BlogImage src={BlogImage1} alt="blog-image" />
          <BlogTitle>
            How To Convert PDF To Word For Free: Windows, Mac, Mobile (2023)
          </BlogTitle>
          <BlogDescription>
            Learn how to convert PDF to Word for free on Windows, Mac, iPhone, &
            Android! The best free tool for 2023.
          </BlogDescription>
          <BlogReadTime>7 min read</BlogReadTime>
        </BlogCard>
        <BlogCard
          onClick={() =>
            navigate("/blog/guides/how-to-password-protect-a-pdf/")
          }
        >
          <BlogImage src={BlogImage2} alt="blog-image" />
          <BlogTitle>
            How To Password Protect A PDF For Free: Windows And Mac (2023)
          </BlogTitle>
          <BlogDescription>
            Learn how to password protect a PDF on Windows, Mac, and mobile!
            Free, online, or with a PDF editor. Step-by-step instructions.
          </BlogDescription>
          <BlogReadTime>7 min read</BlogReadTime>
        </BlogCard>
        <BlogCard
          onClick={() => navigate("/blog/guides/how-to-convert-pdf-to-jpg/")}
        >
          <BlogImage src={BlogImage3} alt="blog-image" />
          <BlogTitle>How to Convert PDF to JPG in 2023</BlogTitle>
          <BlogDescription>
            Learn how to convert PDF to JPG on Windows, Mac, and your browser!
            Step-by-step instructions on the most popular, reliable tools, and
            the best free tool.
          </BlogDescription>
          <BlogReadTime>5 min read</BlogReadTime>
        </BlogCard>
        <BlogCard
          onClick={() => navigate("/blog/guides/how-to-convert-pdf-to-excel/")}
        >
          <BlogImage src={BlogImage4} alt="blog-image" />
          <BlogTitle>
            How to Convert PDF to Excel (2023): Windows, Mac, Mobile, Online
          </BlogTitle>
          <BlogDescription>
            Learn how to convert PDF to Excel on Windows, Mac, and mobile! Free,
            online, or with a PDF editor. Including the best free tool of 2023.
          </BlogDescription>
          <BlogReadTime>9 min read</BlogReadTime>
        </BlogCard>
      </Carousel>
    </Container>
  )
}

export default BlogSection
