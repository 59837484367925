import React from "react"
import {
  Container,
  Title,
  Description,
  DescriptionBox,
  ImageContainter,
  SvgHolder,
} from "../../../ToolsStyles/ShortDescriptionStyled"

import Desc5 from "../../../../../icons/tools/EasyToUse.svg"

import Desc3 from "../../../../../icons/tools/lp/docx.svg"
import Desc2 from "../../../../../icons/tools/lp/merge/DescIcon3.svg"
import Desc4 from "../../../../../icons/tools/lp/merge/DescIcon4.svg"
import Desc1 from "../../../../../icons/tools/lp/merge/DescIcon5.svg"
import Desc6 from "../../../../../icons/tools/lp/merge/DescIcon6.svg"

const ShortDescriptions = () => {
  return (
    <Container>
      <DescriptionBox>
        <SvgHolder>
          <Desc1 />
        </SvgHolder>
        <Title>No Installation or Sign-up required</Title>
        <Description>
          No downloading software, no signing up necessary. Just visit this
          webpage from your browser, and get started!
        </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          <Desc2 />
        </SvgHolder>
        <Title>Safe & Secure — Your Privacy Matters to Us</Title>
        <Description>Uploaded files are deleted after 24 hours.</Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          <Desc3 />
        </SvgHolder>
        <Title>Why Convert PDF to Word?</Title>
        <Description>
          Convert PDF to Word to edit text and content within your documents
          with ease!
        </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          <Desc4 />
        </SvgHolder>
        <Title>100% Free to Use</Title>
        <Description>
          Our PDF to Word converter is completely free to use! There are no
          limits on the number of PDFs you can convert, or the number of times
          you can use this tool.
        </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          {" "}
          <Desc5 />
        </SvgHolder>
        <Title>Easy to Use</Title>
        <Description>
          Convert PDF to Word, instantly. Simply upload your PDF, press Convert,
          and download!
        </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          {" "}
          <Desc6 />
        </SvgHolder>
        <Title>Works on Any Device</Title>
        <Description>
          Instantly convert PDF to Word right in your browser, on any device!
          Windows, Mac, Chrome, Linux — you name it!
        </Description>
      </DescriptionBox>
    </Container>
  )
}

export default ShortDescriptions
